import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const EnabliPage = () => (
    <Layout>
        <Seo 
            title="Fixitdoc case study | Launch Lab" 
            description="Case study of the Fixitdoc app and Webflow website development work completed by Launch Lab."
        />

        <Hero
            h1="Webflow and mobile app case study"  
            h2="FixitDoc"
        />

        
        <section className="bg-purple section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/fixitdoc-main.png"
                        alt="Fixitdoc mobile app"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                            <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Fixitdoc is a sports injury mobile app (iOS & Android) that provides expert content about sports related injuries.</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>Launch Lab worked with the founder from initial wireframes, mobile app design, React Native development and Webflow website design and development.</p>
                                    <p>While Webflow is used as the CMS for the website and blog, the Webflow API is also used so that Webflow is also the content management system for the mobile application. This enables Fixitdoc to edit content for all their digital properties from one user interface.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/startup-web-designers/" className="btn btn-lg btn-line">Startup web design</Link>
                                    <Link to="/startup-web-application-development/" className="btn btn-lg btn-line">Startup development</Link>
                                    <Link to="/webflow-developer/" className="btn btn-lg btn-line">Webflow development</Link>
                                </div>
                                <div id="bg" className="porti-text-section">
                                    <h3>View project</h3>
                                    <div className="mt-1">
                                        <a href="https://www.fixitdoc.info/" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-purple">View Fixitdoc website</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Start a project"
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default EnabliPage